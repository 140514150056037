'use strict'
import '../../less/variables.less'
import '../../less/main.less'
import '../../less/style.less'
import '../../less/publications.less'

import './buildEnv'
import Vue from 'vue'
import './filters/filters'
import './mixins/mixins'
import Utils from '../utils'
import store from './store/main/store'
import AsyncComputed from 'vue-async-computed'
import draggable from 'vuedraggable'
import vueMoment from 'vue-moment'

import { i18n } from '../ext/i18n'
import ipc from '../electron/ipc'
import {
    USERDATA,
    NOTIFICATIONS,
    CLIENTDATA,
    SOCKET,
} from './store/modulesNames'
import {
    GET_CUSTOM_NOTIFICATION_DATA,
} from './store/gettersTypes'
import {
    ACT_SET_LAST_TIME_ACTIVE,
    ACT_SOCKET_INIT,
    ACT_SET_CONTACT_STATUS,
    ACT_HIDE_SYSTEM_NOTIFICATION,
} from "./store/actionsTypes";

import * as declarations from './declarations'

import DateHelper from '../dateHelper'

// components
import SearchItems from './components/search/Items.vue'
import proto from './protocol'
import Info from './components/info/Info.vue'
import Notice from './components/Notice.vue'
import Notifications from './components/notification/CustomNotification.vue'
import event_bus from './eventBus'
import login from './components/login/Login.vue'
import side from './components/side/Side.vue'
import incommingcall from './components/phone/IncommingCallWrapper.vue'
import MainActivity from './components/main/activity/MainActivity.vue'
import MainHeader from './components/main/header/MainHeader.vue'
import MainContent from './components/main/body/MainContent.vue'
import Alarm from './components/Alarm.vue'
import ContextMenu from './components/contextMenu/ContextMenu.vue'
import storage from './storage'
import EnterConf from './components/videoConfNewManagment/enterConference.vue'
// #if process.env.WEBPACK_BUILD_TARGET === 'web'
import CallWorker from './callWorker'
// #endif
import YandexMapsWorker from './yandexMapsWorker'
import VueExtensions from '../vue-extensions'
import IconImage from './components/IconImage.vue'
import PhoneContainerWebWrapper from './components/phone/PhoneContainerWebWrapper.vue'
import ConfContainerWebWrapper from './components/phone/ConfContainerWebWrapper.vue'
import ModalContainer from './components/modal/ModalContainerMain.vue'
import NotificationCenter from './components/notification/NotificationCenter.vue'
import RadioAudio from './components/radio/RadioAudio.vue'
import IncommingCallCtrl from './components/phone/IncommingCallCtrl.vue'
import SystemNotificationCtrl from './components/notification/SystemNotificationCtrl.vue'
import Element from 'element-ui'

import './checkUserActivity'
import './globalWrappers/'

import Logger from './common/Logger'
const vueLogger = new Logger('Vue')

Vue.config.warnHandler = (msg, vm, trace) => vueLogger.warn(msg + trace)
Vue.config.errorHandler = (error) => vueLogger.error(error)

Vue.use(vueMoment)

Vue.prototype.$bus = event_bus

import VueVirtualScroller from 'vue-virtual-scroller'
import { initHotKeys } from '../ext/hotKeys'
import { updateDevices } from '../ext/mediaDevices'
import { setTrackResizes } from '../ext/trackResizes'

import moment from 'moment'

Vue.use(VueVirtualScroller)

// TODO: По неизвестным причинам удаление следующих двух строк приводит к ошибке. Webpack?
document.body.onfocusd = () => { /* store.dispatch('userdata/isFocused', true) */ }
document.body.onblurd = () => { /* store.dispatch('userdata/isFocused', false) */ }

(function () {
    window.utils = new Utils()

    const isDarkSystemTheme = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    let theme = store.getters['clientdata/getTheme']
    if (!theme) {
        store.commit('clientdata/mutSetTheme', "light")
        theme = 'light'
    }
    if (theme === 'light' || (theme === 'system' && !isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "light")
    if (theme === 'dark' || (theme === 'system' && isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "dark")
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => { 
        let theme = store.getters['clientdata/getTheme']
        if (event.matches && theme === 'system') {
            document.documentElement.setAttribute("data-theme", "dark")
            // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             ipc.send('change-theme', 'dark')
            // #endif
        } 
        else if (theme === 'system') {
            document.documentElement.setAttribute("data-theme", "light")
            // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             ipc.send('change-theme', 'light')
            // #endif
        }
    })
})()

document.ondragover = (e) => {
  e.preventDefault()
  e.stopPropagation()
  e.dataTransfer.effectAllowed = 'move' // fix for MacOS to prevent green plus sign appear under the mouse cursor
}

window.onload = () => {
    // HOTFIX: Т.к. на макос клик по окну в фоне не вызывает событие onclick, а лишь ставит фокус на нём
    // (в связи с чем приходится кликать дважды), чтобы закрыть таким образом модальное окно,
    // main-window-onclick бросается по window.onfocus
    // TODO: переделать на main-window-onfocus
    
    if (navigator.userAgent.indexOf('Mac OS X') !== -1) { //@todo в корневой узел
        let body = document.querySelector('body')
        body && body.classList.add('mac')
    }
    
    // Media devices
    updateDevices(store).catch((e) => { console.log('error updating media devices: ', e)});
    navigator.mediaDevices.ondevicechange = () => { updateDevices(store) } 

    setTrackResizes(event_bus)
}

initHotKeys(window, event_bus, store)

const _utils = {
    install (Vue) {
        Object.defineProperty(Vue.prototype, 'utils', {
            get () { return utils },
        })
    },
}

Vue.use(_utils)

new VueExtensions(Vue)

new Vue({
    el: '#notice',
    components: { Notice, draggable },
    store,
    i18n
})

Vue.use(Element)
Vue.use(AsyncComputed)

Vue.component('search-items', {
    store,
    i18n,
    ...SearchItems,
})

Vue.component('icon-image', {
    store,
    i18n,
    ...IconImage,
})

Vue.component('draggable', draggable)

new Vue({
    el: '#info',
    i18n,
    store,
    components: {
        info: Info,
    },
})

new Vue({
    el: '#modal-central',
    i18n,
    store,
    components: { ModalContainer },
})

new Vue({
    el: '#notification-center',
    i18n,
    store,
    components: { NotificationCenter },
})

new Vue({
    el: '#radio',
    store,
    i18n,
    components: { RadioAudio },
})

new Vue({
    el: '#incomming-call',
    store,
    i18n,
    components: { IncommingCallCtrl }
})

new Vue({
    el: '#system-notification',
    store,
    i18n,
    components: { SystemNotificationCtrl }
})

new Vue({
    el: '#notification',
    data: {
        show: false,
        num: 1,
        timerId: null,
    },
    store,
    components: {
        'notification': Notifications,
    },
    computed: {
        dataNotify () {
            return store.getters[`${NOTIFICATIONS}/${GET_CUSTOM_NOTIFICATION_DATA}`]
        },
    },
    methods: {
        hide () {
            this.show = false
        },
    },
    watch: {
        dataNotify () {
            let self = this
            self.num++
            self.show = true
            clearTimeout(this.timerId)
            this.timerId = setTimeout(function () {
                self.show = false
            }, 6000)
        },
    },
})

window.addEventListener('focus', function () {
    store.commit('content_manager/setDocumentFocus', true)
})
window.addEventListener('blur', function () { store.commit('content_manager/setDocumentFocus', false) })

document.addEventListener('visibilitychange', function () {
    store.commit('content_manager/setDocumentHidden', document.hidden)
}, false)

// TODO: Избавиться от этих глобальных переменных
// TODO: Создать корневой узел Vue

window.declarations = declarations
window.date_helper = new DateHelper()
window.proto = proto
window.Channel = class { //@todo kill me please
    canJoin(status) {
        return !status || status === declarations.channel_user_statuses.INVITED ||
            status === declarations.channel_user_statuses.ABSENT;
    };
    isOwner(privilege) {
        return privilege === declarations.channel_user_privilege.OWNER;
    };
    isBanned(status) {
        return status === declarations.channel_user_privilege.BANNED;
    };
    hasAdminRights(privilege) {
        let x = [
            declarations.channel_user_privilege.ADMIN,
            declarations.channel_user_privilege.OWNER
        ].indexOf(privilege) !== -1;
        return x;
    };
}

function log (str) {
    console.log('App.' + str)
}

window.log = log

export default class App {
    constructor () {
        const self = this
        self.store = store
        self.proto = window.proto
        self.storage = storage
        self.$chatContent = null
        self.contactWorkerl = null
        self.yandexMapsWorker = null
        self.blob_store = null
        self.chat_store = null

        document.addEventListener('DOMContentLoaded', () => {
            self.yandexMapsWorker.init()

            /** Временные обертки для компонентов **/
            new Vue({
                el: '#login',
                store,
                i18n,
                components: { 'login': login },
            })
            new Vue({
                el: '#side',
                i18n,
                store,
                components: { 'side': side },
            })
            new Vue({
                el: '#incommingcall',
                i18n,
                store,
                components: { 'incommingcall': incommingcall },
                computed: {
                    isElectron() {
                        return store.getters['clientdata/getIsElectron']
                    },
                }
            })

            new Vue({
                el: '#main-activity-comp',
                i18n,
                store,
                components: { MainActivity }
            })

            new Vue({
                el: '#main-header-comp',
                store,
                i18n,
                components: {MainHeader}
            });

            new Vue({
                el: '#main-content-comp',
                store,
                i18n,
                components: {MainContent}
            });

            new Vue({
                el: '#top-alarm',
                store,
                i18n,
                components: {Alarm}
            })

            new Vue({
                "el": "#context-menu",
                store,
                i18n,
                components: { ContextMenu }
            })

            new Vue({
                el: '#phone-contain',
                store,
                i18n,
                components: { PhoneContainerWebWrapper },
                computed: {
                    isElectron() {
                        return store.getters['clientdata/getIsElectron']
                    },
                }
            })

            new Vue({
                el: '#conf-contain',
                store,
                i18n,
                components: { ConfContainerWebWrapper },
                computed: {
                    isElectron() {
                        return store.getters['clientdata/getIsElectron']
                    },
                }
            })
        })

        event_bus.$on('logout', () => {
            this.store.dispatch(`${USERDATA}/${ACT_SET_CONTACT_STATUS}`, 'offline')
            this.store.dispatch(`${NOTIFICATIONS}/${ACT_HIDE_SYSTEM_NOTIFICATION}`)
            //self.store.dispatch('userdata/setDnd', 0)  // check [vuex] unknown action type: userdata/setDnd
            window.location.reload()
            //history.go(0)
        })

        self.getContact = (cid) => store.getters['contacts/getMergedContactById'](cid) || {fio: i18n.messages[i18n.locale]['search-comp']['unknown-contact']}

        self.yandexMapsWorker = new YandexMapsWorker()

        self.getLocationImageUrl = self.yandexMapsWorker.getLocationImageUrl
        self.getLocationLinkUrl = self.yandexMapsWorker.getLocationLinkUrl
        // self.getAddressByCoordinates = self.yandexMapsWorker.getAddressByCoordinates
    }

    init (repeat) {
        return this.store.dispatch(`${SOCKET}/${ACT_SOCKET_INIT}`, repeat)
    }

    getPhotoUrl (contact) {
        return this.store.getters['userdata/getPhotoUrlbyCid'](contact.cid)
    };

    getGroupPhotoUrl (img) {
        return this.store.getters['userdata/getHost'] + '/' + declarations.http_get_dirs.CHATS_ICONS + '/' + img
    };

    getChannelPhotoUrl (img) {
        return this.store.getters['userdata/getHost'] + '/' + declarations.http_get_dirs.CHANNELS_ICONS + '/' + img
    };

    getUid () {
        return this.store.getters['userdata/getUid']
    };

    call (cid, video) {
        // this.callWorker.call(cid, null, video);
    };

}
event_bus.$on('body-onfocus', () => store.dispatch('userdata/isFocused', true))
event_bus.$on('body-onblur', () => store.dispatch('userdata/isFocused', false))

let lastTimeMouseMove = new moment().unix()
store.dispatch(`${CLIENTDATA}/${ACT_SET_LAST_TIME_ACTIVE}`, { data: lastTimeMouseMove })
event_bus.$on('mouse-move', () => {
    let newTime = new moment().unix()
    if (newTime !== lastTimeMouseMove) {
        lastTimeMouseMove = newTime
        store.dispatch(`${CLIENTDATA}/${ACT_SET_LAST_TIME_ACTIVE}`, { data: newTime })
    }
})

event_bus.$on('enter-conference', ({type, vcLink}) => {
    const vueInstance = document.getElementById('info').__vue__
    vueInstance.modalClose({ name: 'MeetingScheduler' })
    vueInstance.modalOpen({ component: EnterConf, props: { type, vcLink } })
})

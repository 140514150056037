//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters} from 'vuex'
import {
    CONTENT_MANAGER,
    INFO,
    CONTACTS,
    BLOB,
    AJAX,
    USERDATA,
} from '../store/modulesNames'

import {
    GET_UID,
    GET_HOST,
    IS_CONTACT_HAVE_LOCAL_CHANGES, GET_CONTACT_BY_ID,
} from '../store/gettersTypes'

import {
    ACT_CHANGE_CONTACT,
    ACT_ADD_CONTACT,
    ACT_DELETE_CONTACT,
    ACT_INFO_CLEAR,
    ACT_AJAX_SEND_FILE,
    ACT_GET_FILE_FROM_BLOB_URL,
    ACT_MAIN_TYPE_CLEAR,
    ACT_UPDATE_FAVOURITES,
} from '../store/actionsTypes'

import CustomInput from './custom/CustomInput.vue';
import IconImage from './IconImage.vue';
import ModalDialog from './modal/ModalDialog.vue';

import modal_mixin from './modal/modal-mixin'

import { i18n } from '../../ext/i18n'

import {
    ADDRESS_CONTACT_FIELD_TYPE,
    CONTACT_FIELD_TYPES,
    MULTIPLE_CONTACT_FIELD_TYPES,
    PHONE_CONTACT_FIELD_TYPE,
} from '../constants'

const locale = i18n.messages[i18n.locale]

export default {
    name: "ContactProfoleEdition",
    props: {
        type: {
            type: String,
            required: true
        },
        cid: {
            type: Number,
            required: false
        },
        addingCid: {
            type: Number,
            required: false
        },
        photoSrc: {
            type: String,
            required: false,
        },
        photoLink: {
            type: String,
            required: false,
        },
        newFields: {
            type: Array,
            required: false,
            default: () => {[]}
        },
    },
    data: () => {
        return {
            globalContact: {},
            localContact: {},
            contact: {},
            fio: '',
            photo: null,
            localImageLoaded: false,
            globalImageLoaded: false,
            editedPhoto: false,
            removedPhoto: false,
            fileImage: null,
            CONTACT_FIELD_TYPES,
            requiredFields: [
                CONTACT_FIELD_TYPES.SURNAME,
                CONTACT_FIELD_TYPES.NAME,
                CONTACT_FIELD_TYPES.SECONDNAME,
                CONTACT_FIELD_TYPES.ORGANIZATION,
                CONTACT_FIELD_TYPES.OFFICE,
                CONTACT_FIELD_TYPES.POST,
                CONTACT_FIELD_TYPES.PHONE,
                CONTACT_FIELD_TYPES.MAIL,
            ],
            multipleFields: MULTIPLE_CONTACT_FIELD_TYPES,
            multipleFieldsPhones: PHONE_CONTACT_FIELD_TYPE,
            multipleFieldsAddress: ADDRESS_CONTACT_FIELD_TYPE,
            contextMenu: {
                [CONTACT_FIELD_TYPES.PHONE]: [
                    {name: locale.workphone, type: CONTACT_FIELD_TYPES.WORKPHONE},
                    {name: locale.mobilephone, type: CONTACT_FIELD_TYPES.MOBILEPHONE},
                    {name: locale.homephone, type: CONTACT_FIELD_TYPES.HOMEPHONE},
                    {name: locale.fax, type: CONTACT_FIELD_TYPES.FAX},
                    {name: locale['your-var'], type: CONTACT_FIELD_TYPES.PHONE}
                ],
                [CONTACT_FIELD_TYPES.CHILDREN]: [
                    {name: locale.son, type: CONTACT_FIELD_TYPES.CHILDREN},
                    {name: locale.daughter, type: CONTACT_FIELD_TYPES.CHILDREN}
                ],
                [CONTACT_FIELD_TYPES.MAIL]: [
                    {name: locale['pers-email'], type: CONTACT_FIELD_TYPES.MAIL},
                    {name: locale['work-email'], type: CONTACT_FIELD_TYPES.MAIL},
                    {name: locale['your-var'], type: CONTACT_FIELD_TYPES.MAIL},
                ],
                [CONTACT_FIELD_TYPES.SITE]: [
                    {name: locale['corporate-website'], type: CONTACT_FIELD_TYPES.SITE},
                    {name: locale['your-var'], type: 'masiteil'},
                ],
                [CONTACT_FIELD_TYPES.ICQ]: [
                    {name: locale['personal-icq'], type: CONTACT_FIELD_TYPES.ICQ},
                    {name: locale['work-icq'], type: CONTACT_FIELD_TYPES.ICQ},
                    {name: locale['your-var'], type: CONTACT_FIELD_TYPES.ICQ},
                ],
                [CONTACT_FIELD_TYPES.ADDRESS]: [
                    {name: locale['work-address'], type: CONTACT_FIELD_TYPES.WORKADDRESS},
                    {name: locale['home-address'], type: CONTACT_FIELD_TYPES.HOMEADDRESS},
                    {name: locale['your-var'], type: CONTACT_FIELD_TYPES.ADDRESS}
                ]
            },
            contenteditableFields: {
                [CONTACT_FIELD_TYPES.PHONE]: [],
                [CONTACT_FIELD_TYPES.CHILDREN]: [],
                [CONTACT_FIELD_TYPES.MAIL]: [],
                [CONTACT_FIELD_TYPES.SITE]: [],
                [CONTACT_FIELD_TYPES.ICQ]: [],
                [CONTACT_FIELD_TYPES.ADDRESS]: []
            },
            isAddingField: false,
            compModalProps: {
              delay: 200,
              pivotY: 0.05,
              width: 450,
              height: 'auto',
              adaptive: true,
              styles: "min-width: 300px;"
            }
        }
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus();
            }
        }
    },
    components: {
        'custom-input': CustomInput,
        IconImage,
        ModalDialog
    },
    mixins: [modal_mixin],
    created() {
        if(this.type === 'edit' || this.type === 'add-to-contact') {
            if (this.cid) {
                this.contact = utils.cloneObject(this.formatContact(this.$store.getters['contacts/getMergedContactById'](this.cid).fields))
                this.localContact = utils.cloneObject(this.formatContact(this.$store.getters['contacts/getMergedContactById'](this.cid).fields))
                this.globalContact = utils.cloneObject(this.formatContact(this.$store.getters['contacts/getContactById'](this.cid).fields, true))
                const currentPhoto = this.$store.getters['contacts/getMergedContactById'](this.cid).photo
                
                if (this.type === 'edit') {
                    if (this.photoSrc) this.photo = this.hostUrl + this.photoSrc
                    else if (this.photoLink) this.photo = this.photoLink 
                    else this.photo = currentPhoto 
                }

                if(this.type === 'add-to-contact') { 
                    if (this.photoLink) this.photo = this.photoLink 
                }
            }
        } else {
            this.contact = this.formatContact()
            if (this.photoLink) this.photo = this.photoLink
            else this.photo = this.photoSrc ? this.hostUrl + this.photoSrc : '' 
        }            
    },
    computed: {
        localUser() {
            return !('cid' in this[GET_CONTACT_BY_ID](this.cid))
        },
        haveLocalChanges() {
            return this[IS_CONTACT_HAVE_LOCAL_CHANGES](this.cid)
        },
        iconCanBeRemoved() {
            return Boolean(!this.removedPhoto && this.localImageLoaded)
        },
        iconCanBeRollback() {
            return Boolean(!this.iconCanBeRemoved && this.editedPhoto)
        },
        hostUrl() {
            return this[GET_HOST] + '/'
        },
        ...mapGetters(USERDATA, {uid: GET_UID, [GET_HOST]: GET_HOST}),
        ...mapGetters(CONTACTS, [IS_CONTACT_HAVE_LOCAL_CHANGES, GET_CONTACT_BY_ID])
    },
    methods: {
        formatContact(fields=[], isGlobal = false) {
            if (this.newFields && this.newFields.length && !isGlobal) fields = [...fields, ...this.newFields]
            let self = this
            let contact = {};
            fields.forEach(item => {
                item.value = item.value && item.value.trim()
                if(this.multipleFields.includes(item.type)) {
                    if(!(getGroupName(item.type) in contact)) {
                        contact[getGroupName(item.type)] = []
                        contact[getGroupName(item.type)].push(item)
                    } else contact[getGroupName(item.type)].push(item)                  
                } else contact[item.type] = item
            });
            
            this.requiredFields.forEach(item => {
                if (!(item in contact) && [CONTACT_FIELD_TYPES.PHONE, CONTACT_FIELD_TYPES.MAIL].includes(item)) contact[item] = [{type: item, value: ''}]
                else if(!(item in contact)) contact[item] = {type: item, value: ''}
            })
            this.fio = contact[CONTACT_FIELD_TYPES.SURNAME].value +
                (contact[CONTACT_FIELD_TYPES.NAME].value.length ? ' ' : '') + contact[CONTACT_FIELD_TYPES.NAME].value
            return contact;

            function getGroupName(type) {
                if(self.multipleFieldsPhones.includes(type)) return CONTACT_FIELD_TYPES.PHONE
                else if(self.multipleFieldsAddress.includes(type)) return CONTACT_FIELD_TYPES.ADDRESS
                else return type
            }
        },
        globalValueBtn(type, index) {
            let permission = !this.localUser && this.cid !== this.uid
            let isArray = typeof index === 'number'

            if(permission && isArray) {
                if(this.globalContact[type] && this.globalContact[type][index] && this.globalContact[type][index].value !== this.contact[type][index].value) return true
                else if((!this.globalContact[type] || !this.globalContact[type][index]) && this.contact[type][index].value) return true
                else return false
            } else if(permission && !isArray) {
                if(this.globalContact[type] && this.globalContact[type].value !== this.contact[type].value) return true
                else if(!this.globalContact[type] && this.contact[type].value) return true
                else return false
            } else return false
        },
        isDisabled(type, index) {
            if(!this.localUser && this.cid !== this.uid && this.globalContact[type] && this.globalContact[type][index]) return true
            else return false
        },
        close() {
            this.$modal.hide('Contact-profile-edition');
        },
        addField() {
            let open = this.cmOpen;
            let handlers = [];
            let arr = [
                {name: locale.nick, field: CONTACT_FIELD_TYPES.NIK},
                {name: locale.information.room, field: CONTACT_FIELD_TYPES.ROOM},
                {name: locale.information.head, field: CONTACT_FIELD_TYPES.LEADER},
                {name: locale.information.helper, field: CONTACT_FIELD_TYPES.HELPER},
                {name: locale['workhours-start'], field: CONTACT_FIELD_TYPES.WORKSTART},
                {name: locale['workhours-end'], field: CONTACT_FIELD_TYPES.WORKFINISH},
                {name: locale.loginPage.remote, field: CONTACT_FIELD_TYPES.PHONE},
                {name: locale.mail, field: CONTACT_FIELD_TYPES.MAIL},
                {name: locale.information.icq, field: CONTACT_FIELD_TYPES.ICQ},
                {name: locale.information.website, field: CONTACT_FIELD_TYPES.SITE},
                {name: locale.address, field: CONTACT_FIELD_TYPES.ADDRESS},
                {name: locale.information.birthday, field: CONTACT_FIELD_TYPES.BIRTHDAY},
                {name: locale.information.spouse, field: CONTACT_FIELD_TYPES.PARTNER},
                {name: locale.information.children, field: CONTACT_FIELD_TYPES.CHILDREN},
                {name: locale.notes, field: CONTACT_FIELD_TYPES.NOTE},
            ]
            arr.forEach(item => {
                if(this.multipleFields.includes(item.field)) handlers.push({item_name: item.name, handler: () => this.addContactElement(item.field, item.name)})
                else if(!(item.field in this.contact)) handlers.push({item_name: item.name, handler: () => this.addContactElement(item.field, item.name)})
            })
            open(event, handlers, 'bottom-right', {
                onClose: () => this.isAddingField = true
            })
        },
        addContactElement(type, name, value = '') {
            if(this.multipleFields.includes(type)) {
                if(!(type in this.contact)) this.$set(this.contact, type, [])
                this.contact[type].push({ type, name, value })
            } else {
                this.$set(this.contact, type, { type, value })
            }
        },
        deleteLocalUser() {
            
            const close = () => {
                this.$store.dispatch(`${CONTACTS}/${ACT_DELETE_CONTACT}`, {cid: this.cid})
                this.$store.dispatch(`${INFO}/${ACT_INFO_CLEAR}`, {cid: this.cid})
                this.$store.dispatch(`${CONTENT_MANAGER}/${ACT_MAIN_TYPE_CLEAR}`, null)
                this.modalClose()
            }

            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('delete-cont-conf'),
                    btnOk: {
                        cb: () => close()
                    }
                }
            })


        },
        backToGlobal() {

            const remove = () => {
                this.doRemovePhoto()
                this.contact = utils.cloneObject(this.globalContact)
            }

            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('reset-fields-conf'),
                    btnOk: {
                        cb: () => remove()
                    }
                }
            })
        },
        cancel() {
            if(this.checkFields()) {
                this.modalOpen({
                    name: 'confirm',
                    props: {
                        text: this.$t('modal.close-data-confirm'),
                        btnOk: {
                            cb: () => this.modalClose()
                            }
                        }
                })

                
            } else this.modalClose()
        },
        async save() {
            if(this.editedPhoto) this.contact[CONTACT_FIELD_TYPES.PHOTO] = {type: CONTACT_FIELD_TYPES.PHOTO, value: await this.savePhoto()}
            else if(this.removedPhoto) this.contact[CONTACT_FIELD_TYPES.PHOTO] = {type: CONTACT_FIELD_TYPES.PHOTO, value: ''} //@todo
            else if (this.type === 'create' && this.photoLink) {
                const photoFile = await this.getFileName(this.photoLink)
                this.contact[CONTACT_FIELD_TYPES.PHOTO] = {type: CONTACT_FIELD_TYPES.PHOTO, value: photoFile}
            }
            let changes = this.checkFields()
            if (changes === undefined) changes = true
            if(changes) {
                let fields = this.getChangedFields()
                if(this.type === 'create') this.$store.dispatch(`${CONTACTS}/${ACT_ADD_CONTACT}`, {fields})
                else if(this.cid === this.uid) this.$store.dispatch(`${CONTACTS}/${ACT_CHANGE_CONTACT}`, {fields})
                else this.$store.dispatch(`${CONTACTS}/${ACT_CHANGE_CONTACT}`, {cid: this.cid, fields})
                this.$store.dispatch(`${CONTACTS}/${ACT_UPDATE_FAVOURITES}`)
            }
            this.modalClose()
        },
        checkFields() {
            for(let key in this.contact) {
                if(Array.isArray(this.contact[key])) {
                    if(!(key in this.localContact) || this.contact[key].length !== this.localContact[key].length) return true;
                    for(let i = 0; i < this.localContact[key].length; i++) {
                        for(let keyObj in this.localContact[key][i]) {
                            if(this.localContact[key][i][keyObj] !== this.contact[key][i][keyObj]) return true;
                        }
                    }
                } else {
                    for(let keyObj in this.contact[key]) {
                        if(!(key in this.localContact) || this.contact[key][keyObj] !== this.localContact[key][keyObj])  {
                            return true;
                        }
                    }
                }
            }
        },
        getChangedFields() {
            let fields = [];
            for(let key in this.contact) {
                if(Array.isArray(this.contact[key])) {
                    if(!(key in this.globalContact)) {
                        this.contact[key].forEach(item => {
                            if (item?.value) fields.push(item)
                        })    
                    } else {
                        this.contact[key].forEach((item, i) => {
                            let fildsTypeNotEqual = this.globalContact[key].type !== this.contact[key][i].type
                            let fildsNameNotEqual = this.globalContact[key].name !== this.contact[key][i].name
                            let fildsValueNotEqual = this.globalContact[key].value !== this.contact[key][i].value
                            if(!this.globalContact[key][i] || fildsTypeNotEqual || fildsNameNotEqual|| fildsValueNotEqual) fields.push(item)
                        }) 
                    }
                } else {
                    if(!(key in this.globalContact)) {
                        if (this.contact[key]?.value) fields.push(this.contact[key])
                    } else {
                        for(let keyObj in this.contact[key]) {
                            if(this.contact[key][keyObj] !== this.globalContact[key][keyObj]) {
                                fields.push(this.contact[key]);
                                break;
                            }
                        }
                    }
                }
            }
            return fields;
        },
        getAllFields() {
            let fields = [];
            for(let key in this.contact) {
                if(Array.isArray(this.contact[key])) {
                    for(let i = 0; i < this.contact[key].length; i++) {
                        this.contact[key][i].value && fields.push(this.contact[key][i]);
                    }
                } else {
                    this.contact[key].value && fields.push(this.contact[key]);
                }
            }
            return fields;
        },
        doMenu(event, index, position, type) {
            let self = this
            let open = this.cmOpen;
            let handlers = [];
            let count = this.contextMenu[type].length;

            for(let i = 0; i < count; i++) {
                handlers.push({
                    item_name: self.contextMenu[type][i].name,
                    handler() {
                        self.$set(self.contact[type][index], 'name', self.contextMenu[type][i].name)
                        self.$set(self.contact[type][index], 'type', self.contextMenu[type][i].type)
                        self.checkContenteditableFields(self.contextMenu[type][i].name, type, index)
                    }
                });
            }
            open(event, handlers, position);
        },
        checkContenteditableFields(name, type, index) {
            let value = this.contenteditableFields[type].indexOf(index)
            if(name === locale['your-var'] &&  value > -1) return
            else if(name === locale['your-var'] && value === -1) this.contenteditableFields[type].push(index)
            else if(value > -1) this.contenteditableFields[type].splice(value, 1)
        },
        contenteditable(type, index) {
            return this.contenteditableFields[type].includes(index)
        },
        async doAddImage(e) {
            if(e.target.files[0] && e.target.files[0].type.includes('image')) {
                this.fileImage = e.target.files[0]
                this.photo = await this.getBase64(e.target.files[0])
                this.editedPhoto = true
                this.removedPhoto = false
            }
        },
        doOnImageLoad({firstSrc}) {
            if (this.editedPhoto) return
            if (firstSrc) {
                if (this.haveLocalChanges) this.localImageLoaded = true
                else this.globalImageLoaded = true
            } else {
                this.globalImageLoaded = true
            }
        },
        doRemovePhoto() {
            this.photo = this.$store.getters['contacts/getContactById'](this.cid).photo
            this.removedPhoto = true
        },
        doRollbackPhoto() {
            this.fileImage = null
            this.photo = this.$store.getters['contacts/getMergedContactById'](this.cid).photo
            this.removedPhoto = this.editedPhoto = false
        },
        savePhoto() {
            return this.$store.dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                url: declarations.http_post_dirs.CONTACTS_IMG,
                file: this.fileImage,
            })
        },
        doFileSelect: function() {
            document.getElementById('inputFotoUpload').click();
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        doGetGlobalValue(key, index) {
            let target = this.contact[key];
            let value;

            if(!isNaN(index)) {
                if(!this.globalContact[key] || !this.globalContact[key][index]) value = ""
                else value = this.globalContact[key][index].value
                this.$set(this.contact[key][index], 'value', value)
            } else {
                if(!this.globalContact[key]) value = ""
                else value = this.globalContact[key].value
                this.$set(target, 'value', value)
            }
        },
        async getFileName(photo) {
            const file = await this[ACT_GET_FILE_FROM_BLOB_URL]({url: photo})
            return this[ACT_AJAX_SEND_FILE]({url: declarations.http_post_dirs.CHAT_DATA_DIR, file})
        },
        ...mapActions(AJAX, [ACT_AJAX_SEND_FILE]),
        ...mapActions(BLOB, [ACT_GET_FILE_FROM_BLOB_URL]),
    },
    watch: {
        photo() {
            this.localImageLoaded = this.globalImageLoaded = false
        }
    }
}

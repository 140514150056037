'use strict';
import Vue from 'vue';
import proto from '../../protocol';
import ipc from '../../../electron/ipc';
import {
    ACT_ADD_NOTIFICATION,
    ACT_HANDLE_HISTORY_CALL_CHANGE_EVENT,
    ACT_HANDLE_HISTORY_CALL_EVENT, ACT_UPDATE_CALLS_AFTER_CONTACT_DELETED,
} from '../actionsTypes'
import {NOTIFICATIONS} from "../modulesNames";
import { i18n } from '../../../ext/i18n'
import event_bus from '../../eventBus';
import moment from 'moment';
import {CONTACT_FIELD_TYPES} from '../../constants'
import {GET_CALLS_HISTORY_LIST} from '../gettersTypes'

export default  {
    namespaced: true,
    state: {
        calls: {},
        unwatchedCalls: {},
        watchedCallsStatus: false,
        timerChangeUnwatchedCalls: null
    },
    getters: {
        [GET_CALLS_HISTORY_LIST](state) {
            return Object.values(state.calls).map(call => {
                return {
                    ...call,
                    ...{
                        date: formatTime(call.epochTime),
                        dateDetails: formatTimeDetails(call.epochTime)
                    }
                }
            });
        },
        getUnwatchedCallsCount(state) {
            if(state.unwatchedCalls) return Object.keys(state.unwatchedCalls).length
        },
        getUnwatchedCalls(state) {
            return Object.values(state.unwatchedCalls)
        },
        getWatchedCallsStatus(state) {
            return state.watchedCallsStatus
        }
    },
    actions: {
        updateCalls: ({commit, dispatch, rootGetters}) => new Promise((resolve, reject) =>  {
            proto.getHistoryCalls({ count: 100 }, (items) => {
                let newUnwatched = false
                items.forEach((call) => {
                    commit('addCall', call)
                    if (call.type !== 'out' && !call.duration && !call.status) {
                        commit('addUnwatchedCall', call)
                        newUnwatched = true
                    }
                })
                dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, {type: 'call'})
                resolve()
            })
        }),
        updateStatus({getters, dispatch}, params) {
            proto.setHistoryCallStatus(params, data => {
                if (data && data.rev) dispatch(ACT_HANDLE_HISTORY_CALL_CHANGE_EVENT, data)
            })
        },
        [ACT_UPDATE_CALLS_AFTER_CONTACT_DELETED]({commit}, params) {
            commit('updateCallsAfterDeleted', params)
        },
        [ACT_HANDLE_HISTORY_CALL_EVENT]({commit}, call) {
            commit('addCall', call)
        },
        [ACT_HANDLE_HISTORY_CALL_CHANGE_EVENT]({state, commit, dispatch}, params) {
            console.log('updateCallChangeEvent')
            const call = state.calls[params.id]
            if (call) {
                if (params.status === 'deleted') Vue.delete(state.calls, params.id)
                else Vue.set(call, 'status', params.status);
            }
            let changeUnwatchedCalls = false
            const unwatchedCall = state.unwatchedCalls[params.id]
            if (unwatchedCall) {
                Vue.delete(state.unwatchedCalls, params.id)
                changeUnwatchedCalls = true
            }
            if (changeUnwatchedCalls) dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, {type: 'update'})
        },
    },
    mutations: {
        addCall: (state, call) => {
            extendCall(call)
            Vue.set(state.calls, call.id, call)
        },
        addUnwatchedCall(state, call) {
            Vue.set(state.unwatchedCalls, call.id, call)
        },
        updateCalls: function (state, calls) {
            Vue.set(state, 'calls', calls);
        },
        updateUnwatchedCalls: function(state, params) {
            if(Array.isArray(state.unwatchedCalls) && state.unwatchedCalls.length !== params.length) {
                app.store.dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, {type: 'call'});
            }
            Vue.set(state, 'unwatchedCalls', params);
        },
        updateCallsAfterDeleted: (state, params) => {
            const { cid } = params
            for (let call of Object.values(state.calls)) {
                if(call.cid === cid) {
                    call.cid = undefined
                    call.fio = call.number
                }
            }
        },
    }
};

function extendCall(call) {
    call.epochTime = moment().subtract(call.time, 'seconds').unix()
}

let locale = i18n.messages[i18n.locale]
let localeFormatTime = locale['channel-comp']

function formatTime(timeCall) {
    let locale = localeFormatTime
    let result;
    let timeCallFormat = moment.unix(timeCall);
    let today = moment().startOf('day');
    let yesterday = moment().subtract(1, 'days').startOf('day');
    let twoDays = moment().subtract(2, 'days').startOf('day');
    if(timeCallFormat.isAfter(today)) {
        result = timeCallFormat.format('HH:mm');
    } else if(timeCallFormat.isBefore(today) && timeCallFormat.isAfter(yesterday)) {
        result = locale.yesterday;
    } else if(timeCallFormat.isBefore(yesterday) && timeCallFormat.isAfter(twoDays)) {
        result = locale['before-yest'];
    } else {
        result = timeCallFormat.format('DD MMM YYYY');
    }
    return result;
}
function formatTimeDetails(timeCall) {
    let result = {
        data: null,
        time: null
    };
    let timeCallFormat = moment.unix(timeCall);
    let today = moment().startOf('day');
    let yesterday = moment().subtract(1, 'days').startOf('day');
    let twoDays = moment().subtract(2, 'days').startOf('day');
    if(timeCallFormat.isAfter(today)) {
        result.data = locale.settings.today;
        result.time = timeCallFormat.format('HH:mm');
    } else if(timeCallFormat.isBefore(today) && timeCallFormat.isAfter(yesterday)) {
        result.data = locale['channel-comp'].yesterday;
        result.time = timeCallFormat.format('HH:mm');
    } else if(timeCallFormat.isBefore(yesterday) && timeCallFormat.isAfter(twoDays)) {
        result.data = locale['channel-comp']['before-yest'];
        result.time = timeCallFormat.format('HH:mm');
    } else {
        result.data = timeCallFormat.format('DD MMM YYYY');
        result.time = timeCallFormat.format('HH:mm');
    }
    return result;
}

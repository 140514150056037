import { MEETING_ERRORS } from '../protocolErrors/meetings'

export const MEETINGS_API_EVENTS = {
    CHANGE_MEETING: 'change-meeting-event',
    DELETE_MEETING: 'delete-meeting-event',
    MEETING_STATE: 'meeting-state-event',
    MEETING_CONNECT: 'meeting-connect-event',
    MEETING_DISCONNECT: 'meeting-disconnect-event',
    MEETING_ANSWER: 'meeting-answer-event',
    MEETING_ADD_CANDIDATE: 'meeting-add-candidate-event',
    MEETING_REMOVE_CANDIDATE: 'meeting-remove-candidate-event',
    CHANGE_MEETING_USER: 'change-meeting-user-event',
    MEETING_INVITE_USER: 'meeting-invite-user-event',
    MEETING_REJECT_USER: 'meeting-reject-user-event',
    MEETING_INVITE: 'meeting-invite-event',
    MEETING_COMMAND: 'meeting-command-event',
    MEETING_SHARING_START: 'meeting-sharing-start-event',
    MEETING_SHARING_STOP: 'meeting-sharing-stop-event',
}

class MeetingError extends Error {
    /**
     * @param {MEETING_ERRORS} message
     */
    constructor(message) {
        super(message)
        this.message = message
    }
}

export default {
    emitters: {
        addMeeting(payload) {
            return new Promise((resolve, reject) => {
                this.log(`add-meeting: > ${JSON.stringify(payload)}`)
                this.socket.emit('add-meeting', payload, result => {
                    if (!result.error) {
                        if (result.meetingId) {
                            this.log(`add-meeting: < ${result.meetingId}`)
                            resolve(result.meetingId)
                        } else {
                            this.log(`add-meeting: < api error`)
                            reject(new Error('api error'))
                        }
                    } else {
                        this.log(`add-meeting: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        changeMeeting(payload) {
            return new Promise((resolve, reject) => {
                this.log(`change-meeting: > ${JSON.stringify(payload)}`)
                this.socket.emit('change-meeting', payload, result => {
                    if (!result.error) {
                        this.log(`change-meeting: < success`)
                        resolve()
                    } else {
                        this.log(`change-meeting: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        deleteMeeting(payload) {
            return new Promise((resolve, reject) => {
                this.log(`delete-meeting: > ${JSON.stringify(payload)}`)
                this.socket.emit('delete-meeting', payload, result => {
                    if (!result.error) {
                        this.log(`delete-meeting: < success`)
                        resolve()
                    } else {
                        this.log(`delete-meeting: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        getMeetingList() {
            return new Promise((resolve, reject) => {
                this.log(`get-meeting-list: >`)
                this.socket.emit('get-meeting-list', null, result => {
                    if (result) {
                        this.log(`get-meeting-list: < ${JSON.stringify(result)}`)
                        resolve(result)
                    } else {
                        this.log(`get-meeting-list: < api error`)
                        reject(new Error('api error'))
                    }
                })
            })
        },
        getMeetingInfo(payload) {
            return new Promise(async (resolve, reject) => {
                this.log(`get-meeting-info: > ${JSON.stringify(payload)}`)
                this.socket.emit('get-meeting-info', payload, meeting => {
                    if (meeting) {
                        this.log(`get-meeting-info: < ${JSON.stringify(meeting)}`)
                        resolve(meeting)
                    } else {
                        this.log(`get-meeting-info: < api error`)
                        reject(new Error('api error'))
                    }
                })
            })
        },
        /**
         * Запрос запуска конференции
         * @param payload
         * @param {string} payload.meetingId
         * @return {Promise<null>}
         * @throws {MeetingError}
         */
        meetingStart(payload) {
            return new Promise((resolve, reject) => {
                this.log(`meeting-start: > ${JSON.stringify(payload)}`)
                this.socket.emit('meeting-start', payload, result => {
                    if (!result.error) {
                        this.log(`meeting-start: < success`)
                        resolve()
                    } else {
                        this.log(`meeting-start: < error ${result.error}`)
                        reject(new MeetingError(result.error))
                    }
                })
            })
        },
        meetingStop(payload) {
            return new Promise((resolve, reject) => {
                this.log(`meeting-stop: > ${JSON.stringify(payload)}`)
                this.socket.emit('meeting-stop', payload, result => {
                    if (!result.error) {
                        this.log(`meeting-stop: < success`)
                        resolve()
                    } else {
                        this.log(`meeting-stop: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        meetingEnter(payload) {
            return new Promise((resolve, reject) => {
                this.log(`meeting-enter: > ${JSON.stringify(payload)}`)
                this.socket.emit('meeting-enter', payload, result => {
                    if (!result.error) {
                        this.log(`meeting-enter: < ${JSON.stringify(result)}`)
                        resolve(result)
                    } else {
                        this.log(`meeting-enter: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        meetingExit(payload) {
            return new Promise((resolve, reject) => {
                this.log(`meeting-exit: > ${JSON.stringify(payload)}`)
                this.socket.emit('meeting-exit', payload, result => {
                    if (!result.error) {
                        this.log(`meeting-exit: < success`)
                        resolve()
                    } else {
                        this.log(`meeting-exit: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        sendMeetingOffer(payload) {
            return new Promise((resolve, reject) => {
                this.log(`send-meeting-offer: > ${JSON.stringify(payload)}`)
                this.socket.emit('send-meeting-offer', payload, result => {
                    if (!result.error) {
                        this.log(`send-meeting-offer: < success`)
                        resolve()
                    } else {
                        this.log(`send-meeting-offer: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        sendMeetingAddCandidate(payload) {
            return new Promise((resolve, reject) => {
                this.log(`send-meeting-add-candidate: > ${JSON.stringify(payload)}`)
                this.socket.emit('send-meeting-add-candidate', payload, result => {
                    if (!result.error) {
                        this.log(`send-meeting-add-candidate: < success`)
                        resolve()
                    } else {
                        this.log(`send-meeting-add-candidate: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        sendMeetingRemoveCandidate(payload) {
            return new Promise((resolve, reject) => {
                this.log(`send-meeting-remove-candidate: > ${JSON.stringify(payload)}`)
                this.socket.emit('send-meeting-remove-candidate', payload, result => {
                    if (!result.error) {
                        this.log(`send-meeting-remove-candidate: < success`)
                        resolve()
                    } else {
                        this.log(`send-meeting-remove-candidate: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        changeMeetingUser(payload) {
            return new Promise((resolve, reject) => {
                this.log(`change-meeting-user: > ${JSON.stringify(payload)}`)
                this.socket.emit('change-meeting-user', payload, result => {
                    if (!result.error) {
                        this.log(`change-meeting-user: < success`)
                        resolve()
                    } else {
                        this.log(`change-meeting-user: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        deleteMeetingUser(payload) {
            return new Promise((resolve, reject) => {
                this.log(`delete-meeting-user: > ${JSON.stringify(payload)}`)
                this.socket.emit('delete-meeting-user', payload, result => {
                    if (!result.error) {
                        this.log(`delete-meeting-user: < success`)
                        resolve()
                    } else {
                        this.log(`delete-meeting-user: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        meetingInviteUsers(payload) {
            return new Promise((resolve, reject) => {
                this.log(`meeting-invite-users: > ${JSON.stringify(payload)}`)
                this.socket.emit('meeting-invite-users', payload, result => {
                    if (!result.error) {
                        this.log(`meeting-invite-users: < success`)
                        resolve()
                    } else {
                        this.log(`meeting-invite-users: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        meetingRejectUser(payload) {
            return new Promise((resolve, reject) => {
                this.log(`meeting-reject-user: > ${JSON.stringify(payload)}`)
                this.socket.emit('meeting-reject-user', payload, result => {
                    if (!result.error) {
                        this.log(`meeting-reject-user: < success`)
                        resolve()
                    } else {
                        this.log(`meeting-reject-user: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        sendMeetingCommand(payload) {
            return new Promise((resolve, reject) => {
                this.log(`send-meeting-command: > ${JSON.stringify(payload)}`)
                this.socket.emit('send-meeting-command', payload, result => {
                    if (!result.error) {
                        this.log(`send-meeting-command: < success`)
                        resolve()
                    } else {
                        this.log(`send-meeting-command: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        meetingSharingStart(payload) {
            return new Promise((resolve, reject) => {
                this.log(`meeting-sharing-start: > ${JSON.stringify(payload)}`)
                this.socket.emit('meeting-sharing-start', payload, result => {
                    if (!result.error) {
                        this.log(`meeting-sharing-start: < success`)
                        resolve(result)
                    } else {
                        this.log(`meeting-sharing-start: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
        meetingSharingStop(payload) {
            return new Promise((resolve, reject) => {
                this.log(`meeting-sharing-stop: > ${JSON.stringify(payload)}`)
                this.socket.emit('meeting-sharing-stop', payload, result => {
                    if (!result.error) {
                        this.log(`meeting-sharing-stop: < success`)
                        resolve(result)
                    } else {
                        this.log(`meeting-sharing-stop: < error ${result.error}`)
                        reject(new Error(result.error))
                    }
                })
            })
        },
    },
    listeners: {
        [MEETINGS_API_EVENTS.CHANGE_MEETING](data) {
            this.log('bind: < change-meeting-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.CHANGE_MEETING, data)
        },
        [MEETINGS_API_EVENTS.DELETE_MEETING](data) {
            this.log('bind: < delete-meeting-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.DELETE_MEETING, data)
        },
        [MEETINGS_API_EVENTS.MEETING_STATE](data) {
            this.log('bind: < meeting-state-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_STATE, data)
        },
        [MEETINGS_API_EVENTS.MEETING_CONNECT](data) {
            this.log('bind: < meeting-connect-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_CONNECT, data)
        },
        [MEETINGS_API_EVENTS.MEETING_DISCONNECT](data) {
            this.log('bind: < meeting-disconnect-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_DISCONNECT, data)
        },
        [MEETINGS_API_EVENTS.MEETING_ANSWER](data) {
            this.log('bind: < meeting-answer-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_ANSWER, data)
        },
        [MEETINGS_API_EVENTS.MEETING_ADD_CANDIDATE](data) {
            this.log('bind: < meeting-add-candidate-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_ADD_CANDIDATE, data)
        },
        [MEETINGS_API_EVENTS.MEETING_REMOVE_CANDIDATE](data) {
            this.log('bind: < meeting-remove-candidate-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_REMOVE_CANDIDATE, data)
        },
        [MEETINGS_API_EVENTS.CHANGE_MEETING_USER](data) {
            this.log('bind: < change-meeting-user-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.CHANGE_MEETING_USER, data)
        },
        'meeting-user-event'(data) { //@todo ошибка?
            this.log('bind: < change-meeting-user-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.CHANGE_MEETING_USER, data)
        },
        [MEETINGS_API_EVENTS.MEETING_INVITE_USER](data) {
            this.log('bind: < meeting-invite-user-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_INVITE_USER, data)
        },
        [MEETINGS_API_EVENTS.MEETING_REJECT_USER](data) {
            this.log('bind: < meeting-reject-user-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_REJECT_USER, data)
        },
        [MEETINGS_API_EVENTS.MEETING_INVITE](data) {
            this.log('bind: < meeting-invite-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_INVITE, data)
        },
        [MEETINGS_API_EVENTS.MEETING_COMMAND](data) {
            this.log('bind: < meeting-command-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_COMMAND, data)
        },
        [MEETINGS_API_EVENTS.MEETING_SHARING_START](data) {
            this.log('bind: < meeting-command-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_SHARING_START, data)
        },
        [MEETINGS_API_EVENTS.MEETING_SHARING_STOP](data) {
            this.log('bind: < meeting-command-event: ' + JSON.stringify(data))
            this.emit(MEETINGS_API_EVENTS.MEETING_SHARING_STOP, data)
        },
    },
}
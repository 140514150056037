//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import {CLIENTDATA, CONTACTS} from '../../store/modulesNames'
import {GET_CONTACTS_FILTER_BY_NAME, GET_LOCALE} from '../../store/gettersTypes'
import {
    ACT_MODAL_CLOSE,
    ACT_SET_CONTACTS_FILTER,
    ACT_UPDATE_SEARCH_FILTER
} from '../../store/actionsTypes'
import ModalDialog from "./ModalDialog.vue"
import ModalBtn from "./ModalBtn.vue"
import modal_mixin from "./modal-mixin"
import { MODAL } from "../../store/modulesNames"
import ListItem from "../custom/ListItem.vue";
import MainContentContainer from "../main/body/MainContentContainer.vue"
import FilterContactType from "./FilterContactType.vue"

import {CONTACT_FIELD_TYPES} from '../../constants'

export default {
    name: "ContactsFilter",
    components: { ModalDialog, ModalBtn, MainContentContainer, ListItem, FilterContactType},
    mixins: [modal_mixin],
    props: {
        // test: {
        //    type: Function
        // },
        calbackItem: {
            type: Function
        }
    },
    data: () => {
        return {
            compModalProps: {
                delay: 350,
                pivotY: 0.05,
                width: 400,
                height: 'auto',
                clickToClose: false
            },
            mapSettings: {
                [CONTACT_FIELD_TYPES.ORGANIZATION]: [],
                [CONTACT_FIELD_TYPES.OFFICE]: [],
                [CONTACT_FIELD_TYPES.POST]: []
            },
            test: CONTACT_FIELD_TYPES,
            mapFilters: [
                {
                    name: 'information.organization',
                    type: CONTACT_FIELD_TYPES.ORGANIZATION,
                    default: 'information.choose-organization'
                },
                {
                    name: 'information.department',
                    type: CONTACT_FIELD_TYPES.OFFICE,
                    default: 'information.choose-department'
                },
                {
                    name: 'information.position',
                    type: CONTACT_FIELD_TYPES.POST,
                    default: 'information.choose-position'
                }
            ],
            mapType: {
                [CONTACT_FIELD_TYPES.ORGANIZATION]: [],
                [CONTACT_FIELD_TYPES.OFFICE]: [],
                [CONTACT_FIELD_TYPES.POST]: []
            },
            settings: {},
            newSettings: {},
            newDataSettings: {
                [CONTACT_FIELD_TYPES.ORGANIZATION]: [],
                [CONTACT_FIELD_TYPES.OFFICE]: [],
                [CONTACT_FIELD_TYPES.POST]: []
            },
            changeSettings: {},
            exist: false
        }
    },
    computed: {
        title () {
            return this.$t('search-comp.search-more')
        },
        existBtn() {
            this.mapType = {...this.mapSettings}
            Object.keys(this.mapType).forEach(el => {
                if (this.mapType[el].length !== 0) this.exist = true
            })
            return this.exist
        },
        ...mapGetters(CLIENTDATA, [GET_LOCALE]),
        ...mapGetters(CONTACTS, [GET_CONTACTS_FILTER_BY_NAME])
    },
    methods: {
        addData (itemType) {
            this.modalOpen({
                component: FilterContactType,
                props: {
                    filter: this.mapSettings,
                    itemType,
                    calbackItem: (data) => {
                        this.settings = data
                        this.mapSettings = this.settings
                    },
                    calbackNewData: (data) => {
                        this.newSettings = data
                        this.newDataSettings = this.newSettings
                    },
                    onClose: () => resolve(false)
                }
            })
        },
        clear (type) {
            this.mapSettings[type] = []
            this.mapType = {...this.mapSettings}
        },
        dataSearch (type) {
            let arr = this.mapType[type]
            let count = arr.length
            if (count === 0) return false
            if (count === 1) return arr[0]
            if (count > 1) {
                if (type === CONTACT_FIELD_TYPES.ORGANIZATION) {
                    let text = ''
                    if (this[GET_LOCALE] === 'ru') {
                        text = utils.declOfNum(count, [ this.$t('information.one-organization'), this.$t('information.several-organization'), this.$t('information.many-organization') ])
                    } else {
                        text = (count === 1) ? this.$t('information.one-organization') : this.$t('information.many-organization')
                    }
                    return `${this.$t('selected-search')} ${count} ${text}`
                } 
                if (type === CONTACT_FIELD_TYPES.OFFICE) {
                    let text = ''
                    if (this[GET_LOCALE] === 'ru') {
                        text = utils.declOfNum(count, [ this.$t('information.one-department'), this.$t('information.several-department'), this.$t('information.many-department') ])
                    } else {
                        text = (count === 1) ? this.$t('information.one-department') : this.$t('information.many-department')
                    }
                    return `${this.$t('selected-search')} ${count} ${text}`
                }
                if (type === CONTACT_FIELD_TYPES.POST) {
                    let text = ''
                    if (this[GET_LOCALE] === 'ru') {
                        text = utils.declOfNum(count, [ this.$t('information.one-position'), this.$t('information.several-position'), this.$t('information.many-position') ])
                    } else {
                        text = (count === 1) ? this.$t('information.one-position') : this.$t('information.many-position')
                    }
                    return `${this.$t('selected-search')} ${count} ${text}`
                }
            }
        },
        unicFilter (arr, arr_new) {
            let arr_values = Object.keys(arr).map(key => { return arr[key] })
            let new_values = Object.keys(arr_new).map(key => { return arr_new[key] })
            let res = []
            for (let key in arr_values) {
                if (new_values.indexOf(arr_values[key]) == -1) {
                    res.push(arr_values[key])
                }
            }
            return res
        },
        addFilter() {
            this[ACT_SET_CONTACTS_FILTER]({ site: this.mapSettings })
            this[ACT_MODAL_CLOSE]({name: "ContactsFilter"})
        },
        onCancel() {
            let { [CONTACT_FIELD_TYPES.ORGANIZATION]: org, [CONTACT_FIELD_TYPES.OFFICE]: office, [CONTACT_FIELD_TYPES.POST]: post } = this.mapSettings
            let { [CONTACT_FIELD_TYPES.ORGANIZATION]: newOrg, [CONTACT_FIELD_TYPES.OFFICE]: newOffice, [CONTACT_FIELD_TYPES.POST]: newPost } = this.newDataSettings
            let obj = {
                [CONTACT_FIELD_TYPES.ORGANIZATION]: this.unicFilter(org, newOrg),
                [CONTACT_FIELD_TYPES.OFFICE]: this.unicFilter(office, newOffice),
                [CONTACT_FIELD_TYPES.POST]: this.unicFilter(post, newPost)
            }
            for (let key in obj) {
                this.changeSettings[key] = obj[key]
            }
            this[ACT_SET_CONTACTS_FILTER]({ site: this.changeSettings })
            this[ACT_MODAL_CLOSE]({name: "ContactsFilter"})
        },
        ...mapActions(MODAL, [ACT_MODAL_CLOSE]),
        ...mapActions(CONTACTS, [ACT_UPDATE_SEARCH_FILTER, ACT_SET_CONTACTS_FILTER])
    },
    async mounted () {
        await this[ACT_UPDATE_SEARCH_FILTER]()
        let list = this[GET_CONTACTS_FILTER_BY_NAME]('site')
        if (Object.keys(list).length !== 0) {
            this.mapSettings = list
        }
    }
}


//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import prettyBytes from 'pretty-bytes'
import ProgressCircle from '../ProgressCircle.vue'
import ChatMessageMixin from './chat-message-mixin'

import { mapGetters } from 'vuex'
import { CONTACTS } from '../../store/modulesNames'
import {
    GET_MERGED_CONTACT_BY_ID,
} from '../../store/gettersTypes'
import ChatMessageMediaViewer from '../mediaViewer/ChatMessageMediaViewer.vue'
import { extToMimeType } from '../../declarations'

export default {
    name: 'chat-regular-file-message',
    props: ['message', 'showFullDate', 'showSize'],
    mixins: [ChatMessageMixin],
    components: {'progress-circle': ProgressCircle},
    computed: {
        id() { return this.message.id },
        msg() { 
            return this.message.msg 
        },
        prettySize() {
            return prettyBytes(+this.msg.size, { locale: this.$t('pretty-bytes-locale') })
        },
        readOnly() {
            return this.msg && this.msg.readOnly
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        showEncryptedAlert() {
            this.modalOpen({
                name: 'alert',
                props: {
                    title: this.$t('alert-encrypted-file-title'),
                    text: this.$t('alert-encrypted-file-text')
                }
            })
        },
        doLoadDocumentFile() {
            this.loadMessageFile({
                id: this.message.id || (this.message.chId + this.message.pubId),
                file: this.message.msg.file,
                size: this.message.msg.size,
                key: this.message.msg.key,
                remoteHost: this.message.remoteServerHost || ''
            }, () => {
                if (this.readOnly) {
                    let type
                    if (this.msg.extension === 'pdf') {
                        type = 'pdf'
                    } else {
                        let mime = extToMimeType[this.msg.extension]
                        if (mime) type = mime.split('/').shift()
                    }
                    this.$modal.show(
                        ChatMessageMediaViewer, {
                            src: this.msg,
                            message: this.message,
                            type: type,
                            readOnly: true,
                        }, {
                            name: 'viewer',
                            delay: 200,
                            width: '100%',
                            height: '100%',
                            classes: ['v--modal', 'v--modal-box', 'no-gap-on-top']
                        })
                } else {
                    this.downloadFile(this.message)
                }
            })
        },
        fioById(cid) {
            return this[GET_MERGED_CONTACT_BY_ID](cid).fio || null
        },
    }
}

import MeetingIOClient from '../../api/roschat/MeetingIOClient'
import { MEETINGS_API_EVENTS } from '../../api/roschat/protocols/meetings'
import EventEmitter from '../../common/EventEmitter'

export const TRANSPORT_EVENTS = {
    API_EVENT: 'api-event',
    ERROR_EVENT: 'error-event'
}

class MeetingTransport extends EventEmitter {
    constructor ({commonProto = null, remoteServer = '', remotePort = 0, remoteEncryption = false}) {
        super()
        this.remoteServer = remoteServer
        this.remotePort = remotePort
        this.remoteEncryption = remoteEncryption
        this.proto = commonProto
    }

    async init() {
        if (this.remoteServer) {
            this.proto = new MeetingIOClient(this.remoteEncryption)

            this.proto.connect(`https://${this.remoteServer}${this.remotePort ? ':' + this.remotePort : ''}`)
            await new Promise((resolve, reject) => {
                this.proto.on('connected', resolve)
                setTimeout(reject, 10000)
            })
        }

        this._subscribeOnProto()
    }

    async meetingStart(payload) {
        return this.proto.meetingStart(payload)
    }
    async meetingStop(payload) {
        return this.proto.meetingStop(payload)
    }
    async getMeetingInfo(payload) {
        return this.proto.getMeetingInfo(payload)
    }
    async meetingEnter(payload) {
        return this.proto.meetingEnter(payload)
    }
    async meetingExit(payload) {
        return this.proto.meetingExit(payload)
    }
    async sendMeetingOffer(payload) {
        return this.proto.sendMeetingOffer(payload)
    }
    async sendMeetingAddCandidate(payload) {
        return this.proto.sendMeetingAddCandidate(payload)
    }
    async sendMeetingRemoveCandidate(payload) {
        return this.proto.sendMeetingRemoveCandidate(payload)
    }
    async changeMeetingUser(payload) {
        return this.proto.changeMeetingUser(payload)
    }
    async deleteMeetingUser(payload) {
        return this.proto.deleteMeetingUser(payload)
    }
    async meetingInviteUsers(payload) {
        return this.proto.meetingInviteUsers(payload)
    }
    async meetingRejectUser(payload) {
        return this.proto.meetingRejectUser(payload)
    }
    async sendMeetingCommand(payload) {
        return this.proto.sendMeetingCommand(payload)
    }

    async meetingSharingStart(payload) {
        return this.proto.meetingSharingStart(payload)
    }

    async meetingSharingStop(payload) {
        return this.proto.meetingSharingStop(payload)
    }

    _subscribeOnProto () {
        this.proto.on('disconnected', () => {
            this.emit(TRANSPORT_EVENTS.ERROR_EVENT, new Error('service error'))
        })
        this.proto.on(MEETINGS_API_EVENTS.CHANGE_MEETING, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.CHANGE_MEETING, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.DELETE_MEETING, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.DELETE_MEETING, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_STATE, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_STATE, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_CONNECT, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_CONNECT, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_DISCONNECT, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_DISCONNECT, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_ANSWER, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_ANSWER, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_ADD_CANDIDATE, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_ADD_CANDIDATE, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_REMOVE_CANDIDATE, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_REMOVE_CANDIDATE, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.CHANGE_MEETING_USER, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.CHANGE_MEETING_USER, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_INVITE_USER, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_INVITE_USER, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_REJECT_USER, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_REJECT_USER, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_INVITE, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_INVITE, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_COMMAND, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_COMMAND, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_SHARING_START, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_SHARING_START, payload})
        })
        this.proto.on(MEETINGS_API_EVENTS.MEETING_SHARING_STOP, (payload) => {
            this.emit(TRANSPORT_EVENTS.API_EVENT, {event: MEETINGS_API_EVENTS.MEETING_SHARING_STOP, payload})
        })
    }

}

export default MeetingTransport
import module, {
    convertRoschatConfToCommonFormat,
    convertCommonConfToRochatFormat,
    getRoschatIdObj,
    ROSCHAT_CONF_SCHEDULE_PROPS
} from '../../modules/video-conf-roschat'

import {
    ACT_GET_CONF_LIST,
    ACT_CONF_CREATE,
    ACT_CONF_DELETE,
    ACT_ROSCHAT_CONF_API_MEETING_START,
    ACT_ROSCHAT_CONF_API_MEETING_STOP,
    ACT_ROSCHAT_CONF_API_MEETING_INFO,
    ACT_ROSCHAT_CONF_API_MEETING_ENTER,
    ACT_ROSCHAT_CONF_API_MEETING_EXIT,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_OFFER,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_ADD_CANDIDATE,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_REMOVE_CANDIDATE,
    ACT_ROSCHAT_CONF_API_CHANGE_MEETING_USER,
    ACT_ROSCHAT_CONF_API_DELETE_MEETING_USER,
    ACT_ROSCHAT_CONF_API_MEETING_INVITE_USERS,
    ACT_ROSCHAT_CONF_API_MEETING_REJECT_USER,
    ACT_ROSCHAT_CONF_API_SEND_MEETING_COMMAND,
    ACT_ROSCHAT_CONF_API_CREATE_TRANSPORT,
    ACT_ROSCHAT_CONF_API_DESTROY_TRANSPORT,
    ACT_ROSCHAT_CONF_HANDLE_API_MEETING_EVENT,
    ACT_CONF_CHANGE,
    ACT_ROSCHAT_CONF_API_SHARING_START,
    ACT_ROSCHAT_CONF_API_SHARING_STOP,
    ACT_SCHEDULE_ADD_APPOINTMENT,
    ACT_SCHEDULE_EDIT_APPOINTMENT,
    ACT_SET_CONFSCALENDAR,
    ACT_DELETE_CONFSCALENDAR,
    ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS,
    ACT_ROSCHAT_CONF_HANDLE_ERROR_MEETING_EVENT,
    ACT_CONF_START,
} from '../../actionsTypes'
import { USERDATA, INTEGRATIONS, USERPROFILE } from '../../modulesNames'
import {GET_ROSCHAT_REDIRECTED_SERVER, GET_CONFSCALENDAR} from '../../gettersTypes'

import MeetingTransport, {TRANSPORT_EVENTS} from '../../../sdk/roschat/MeetingTransport'
// import {MEETINGS_API_EVENTS} from '../../../api/roschat/protocols/meetings'
import { generateHexString } from '../../../common/Encrypter'
import {CONF_SCHEDULE_PROPS} from '../../modules/video-conf-abstract'

import { i18n } from '../../../../ext/i18n'

import moment from 'moment'
import {MEETINGS_API_EVENTS} from '../../../api/roschat/protocols/meetings'

const locale = i18n.messages[i18n.locale]

const notifyBeforeAppointment = 5 // minutes to notify before calendar appointment

let meetingTransport = null

const getters = {

}

const actions = {
    // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//     //[ACT_VC_TOGGLE_MINIMIZE]: () => {},
//     //[ACT_VC_TOGGLE_FULLSCREEN]: () => {},
    // #endif
    async [ACT_GET_CONF_LIST](obj) {
        const list = await proto.getMeetingList() || []
        const result = {
            planned: [],
            passed: [],
        }
        list.forEach(item => {
            const commonFormatItem = convertRoschatConfToCommonFormat(item)
            const isConfNotEnded = commonFormatItem[CONF_SCHEDULE_PROPS.END_TIME] > date_helper.getCurrentUnixTime()
            if (isConfNotEnded || item[ROSCHAT_CONF_SCHEDULE_PROPS.RUNNING]) result.planned.push(commonFormatItem)
            else result.passed.push(commonFormatItem)
        })
        return result
    },
    async [ACT_CONF_CREATE]({ dispatch, rootGetters, root = true }, payload) {
        const { isAddCalendar } = payload
        if (!payload[CONF_SCHEDULE_PROPS.PASSWORD]) {
            try {
                let hexString = await generateHexString()
                payload[CONF_SCHEDULE_PROPS.PASSWORD] = hexString.slice(0,6)
            } catch (e) {
                payload[CONF_SCHEDULE_PROPS.PASSWORD] = '11111111'
            }
        }
        if (!payload[CONF_SCHEDULE_PROPS.START_TIME]) payload[CONF_SCHEDULE_PROPS.START_TIME] = date_helper.getCurrentUnixTime()
        if (!payload[CONF_SCHEDULE_PROPS.END_TIME]) payload[CONF_SCHEDULE_PROPS.END_TIME] = payload[CONF_SCHEDULE_PROPS.START_TIME] + 3600
        await proto.addMeeting(convertCommonConfToRochatFormat(payload))
        try {
            if (isAddCalendar) {
                const ourUrl = new URL(rootGetters[`${USERDATA}/${GET_ROSCHAT_REDIRECTED_SERVER}`])
                const calendarPayload = getCalendarPayload(payload, ourUrl)
                const scheduledAppointment = await dispatch(`${INTEGRATIONS}/${ACT_SCHEDULE_ADD_APPOINTMENT}`, calendarPayload, {root})
                const confsCalendar = { confId: id, appointmentId: scheduledAppointment.id }
                dispatch(`${USERPROFILE}/${ACT_SET_CONFSCALENDAR}`, { confsCalendar }, {root})
            }
        } catch(e) {
            console.log("🚀 ~ file: video-conf-roschat.js:85 ~ e:", e)
        }
        return true
    },
    async [ACT_CONF_CHANGE]({ dispatch , rootGetters}, payload) {
        console.log('~~~~~~~~~ACT_CONF_CHANGE ' + JSON.stringify(payload))
        const { isAddCalendar } = payload
        await proto.changeMeeting(convertCommonConfToRochatFormat(payload))
        try {
            if (isAddCalendar) {
                const copyPayload = JSON.parse(JSON.stringify(payload))
                delete copyPayload.additionalProps
                delete copyPayload.isAddCalendar
                delete copyPayload.periodicity
                const { startTime, endTime, topic } = copyPayload
                const startDate = moment.unix(startTime).format("YYYY-MM-DD")
                const endDate = moment.unix(endTime).format("YYYY-MM-DD")
                const appointments = await dispatch(`${INTEGRATIONS}/${ACT_SCHEDULE_GET_CALENDAR_APPOINTMENTS}`, { type: "exchange", startDate, endDate }, { root: true })
                const appointment = appointments && appointments.find(ap => ap.subject === topic)
                const ourUrl = new URL(rootGetters[`${USERDATA}/${GET_ROSCHAT_REDIRECTED_SERVER}`])
                if (appointment) {
                    const calendarPayload = getCalendarPayload(copyPayload, ourUrl)
                    calendarPayload.id = appointment.id
                    await dispatch(`${INTEGRATIONS}/${ACT_SCHEDULE_EDIT_APPOINTMENT}`, calendarPayload, { root: true })
                    const storedIds = rootGetters[`${USERPROFILE}/${GET_CONFSCALENDAR}`](payload.id)
                    if (!storedIds) {
                        const confsCalendar = { confId: payload.id, appointmentId: appointment.id }
                        dispatch(`${USERPROFILE}/${ACT_SET_CONFSCALENDAR}`, { confsCalendar }, {root: true})                    
                    }
                } else {
                    const calendarPayload = getCalendarPayload(copyPayload, ourUrl)
                    const scheduledAppointment = await dispatch(`${INTEGRATIONS}/${ACT_SCHEDULE_ADD_APPOINTMENT}`, calendarPayload, {root: true})
                    const confsCalendar = { confId: payload.id, appointmentId: scheduledAppointment.id }
                    dispatch(`${USERPROFILE}/${ACT_SET_CONFSCALENDAR}`, { confsCalendar }, {root: true})
                }
            }
        } catch(error) {
            console.log("🚀 ~ file: video-conf-roschat.js:136 ~ error:", error)
        }
        return true
    },
    async [ACT_CONF_START]({ dispatch , rootGetters}, payload) {
        if (meetingTransport) {
            await proto.meetingStart(getRoschatIdObj(payload))
            meetingTransport.emit(TRANSPORT_EVENTS.API_EVENT, {
                    event: MEETINGS_API_EVENTS.MEETING_STATE,
                    payload: {
                        meetingId: payload.id,
                        state: 'online',
                    },
                },
            )
        }
    },
    async [ACT_CONF_DELETE]({ dispatch, root = true }, payload) {
        try {
            proto.deleteMeeting(getRoschatIdObj(payload))
            const { id } = payload
            dispatch(`${USERPROFILE}/${ACT_DELETE_CONFSCALENDAR}`, id, {root})
        } catch (error) {
            console.log("🚀 ~ file: video-conf-roschat.js:124 ~ error:", error)
        }
        return true
    },
    async [ACT_ROSCHAT_CONF_API_CREATE_TRANSPORT]({dispatch, rootGetters}, {server}) {
        const serverUrl = new URL(`https://${server}`)
        const ourUrl = new URL(rootGetters[`${USERDATA}/${GET_ROSCHAT_REDIRECTED_SERVER}`])
        const transportArgs = {}
        if (serverUrl.hostname === ourUrl.hostname) {
            transportArgs.commonProto = proto
        } else {
            transportArgs.remoteServer = serverUrl.hostname
            transportArgs.remotePort = serverUrl.port
            transportArgs.remoteEncryption = true
        }
        meetingTransport = new MeetingTransport(transportArgs)

        await meetingTransport.init()
        meetingTransport.on(TRANSPORT_EVENTS.ERROR_EVENT, (payload) => {
            dispatch(ACT_ROSCHAT_CONF_HANDLE_ERROR_MEETING_EVENT, payload)
        })
        meetingTransport.on(TRANSPORT_EVENTS.API_EVENT, (payload) => {
            dispatch(ACT_ROSCHAT_CONF_HANDLE_API_MEETING_EVENT, payload)
        })
    },
    async [ACT_ROSCHAT_CONF_API_DESTROY_TRANSPORT]() {
        meetingTransport = null //@todo del subscription
    },
    async [ACT_ROSCHAT_CONF_API_MEETING_START](obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.meetingStart(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_MEETING_STOP](obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.meetingStop(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_MEETING_INFO] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.getMeetingInfo(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_MEETING_ENTER] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.meetingEnter(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_MEETING_EXIT] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.meetingExit(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_SEND_MEETING_OFFER] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.sendMeetingOffer(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_SEND_MEETING_ADD_CANDIDATE] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.sendMeetingAddCandidate(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_SEND_MEETING_REMOVE_CANDIDATE] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.sendMeetingRemoveCandidate(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_CHANGE_MEETING_USER] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.changeMeetingUser(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_DELETE_MEETING_USER] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.deleteMeetingUser(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_MEETING_INVITE_USERS] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.meetingInviteUsers(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_MEETING_REJECT_USER] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.meetingRejectUser(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_SEND_MEETING_COMMAND] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.sendMeetingCommand(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_SHARING_START] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.meetingSharingStart(payload)
        return response
    },
    async [ACT_ROSCHAT_CONF_API_SHARING_STOP] (obj, payload) {
        if (!meetingTransport) throw Error('transport not created')
        let response = await meetingTransport.meetingSharingStop(payload)
        return response
    },
}

const getCalendarPayload = (payload, ourUrl) => {
    const { id, password, startTime, endTime, topic } = payload
    const attendees = payload && payload.attendees
    const recurrence = payload && payload.recurrence
    const link = `https://${ourUrl.hostname}/join/?meetingid=${id}&password=${password}`
    let body = `${locale.videoconf['link']}: 
${link}`.replace(/\n/g, "\r\n")
    const calendarPayload = {
        type: 'exchange',
        startTime,
        endTime,
        allDay: false,
        recurrence,
        notifyBeforeStart: notifyBeforeAppointment,
        isPrivate: false,
        subject: topic,
        location: locale.videoconf['location_RC'],
        body
    }
    if (attendees && attendees.length) {
        const attendeesFormed = []
        if (attendees && attendees.length) attendees.map(email => {
            attendeesFormed.push({ address: email })
        })
        calendarPayload.attendees = attendeesFormed
    }
    return calendarPayload    
}


Object.assign(module.getters, getters)
Object.assign(module.actions, actions)
export default module
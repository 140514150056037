import { render, staticRenderFns } from "./QrFile.vue?vue&type=template&id=7dd36c26&scoped=true&lang=pug&"
import script from "./QrFile.vue?vue&type=script&lang=js&"
export * from "./QrFile.vue?vue&type=script&lang=js&"
import style0 from "./QrFile.vue?vue&type=style&index=0&id=7dd36c26&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd36c26",
  null
  
)

export default component.exports
import {
    GET_SID,
    GET_IS_ELECTRON,
    GET_CLIENT_VERSION,
    GET_NEW_CLIENT_INFO,
    GET_CLIENT_CHANGES,
    SHOW_NEW_VERSION_NOTIFY,
    GET_CLIENT_UPDATE_STATUS,
    GET_CLIENT_DOWNLOAD_SIZE,
    GET_CLIENT_DOWNLOAD_CURRENT_SIZE,
    GET_LOCALE,
    GET_CSS_PROPERTY_SUPPORTED,
    GET_CURRENT_TIME,
    GET_CURRENT_DAY,
    GET_LAST_TIME_ACTIVE,
    GET_MINUTES_RELATIVE_TO_TIME,
} from '../gettersTypes'
import {
    ACT_GET_NEW_CLIENT_INFO,
    ACT_UPDATE_CLIENT,
    ACT_CHANGE_LOCALE,
    ACT_SET_CLIENT_INFO,
    ACT_UPDATE_NOTIFICATION,
    ACT_SET_LAST_TIME_ACTIVE,
    ACT_SET_CONTACT_STATUS,
    ACT_UPDATE_CONTACTS_BIRTHDAYS,
} from '../actionsTypes'
import {
    MUT_SET_NEW_CLIENT_INFO,
    MUT_SET_UPDATE_STATUS,
    MUT_SET_CLIENT_DOWNLOAD_SIZE,
    MUT_SET_CLIENT_DOWNLOAD_CURRENT_SIZE,
    MUT_SET_LOCALE,
    MUT_SET_CLIENT_INFO,
    MUT_SET_CURRENT_TIME,
    MUT_SET_NEW_CLIENT_INFO_VIEWED,
    MUT_SET_NEXT_NEW_CLIENT_REQUEST,
    MUT_SET_TIMER_CHECK_UPDATE,
    MUT_SET_CLIENT_CHANGES,
    MUT_SET_LAST_TIME_ACTIVE,
} from '../mutationsTypes'

import {CONTACTS, USERDATA} from '../modulesNames'
import moment from "moment"

export const UPDATE_STATUSES = {
    ERROR: -1,
    UP_TO_DATA: 0,
    HAVE_NEW_VERSION: 1,
    PENDING_INFO: 2,
    DOWNLOADING: 3,
    UPDATING: 4
}

export const UPDATE_ERRORS = {
    GET_INFO_ERROR: 0,
    DOWNLOAD_ERROR: 1,
    UPDATE_ERROR: 2
}

const getBuild = (ver) => ver && (typeof ver === 'string') && +ver.split('.').pop() || 0

const isBuildGreater = (oldVer, newVer) => {
    let buildOld = getBuild(oldVer)
    let buildNew = getBuild(newVer)
    return buildNew > buildOld
}

const offlineMessageTime = 180 // Time in seconds, after which (set-contact-status, {status: ”offline”}) 
let isOfflineMessageSent = false

const state = {
    isElectron: navigator.userAgent.indexOf('Electron') >= 0,
    clientVersionFull: '0.0.0.180',
    clientVersion: null,
    clientArch: null,
    clientChanges: [],
    os: null,
    newClientInfo: null,
    updateStatus: UPDATE_STATUSES.UP_TO_DATA,
    downloadInfo: {
        totalSize: 0,
        currentSize: 0
    },
    socketId: null,
    timerCheckUpdate: null,
    locale: 'ru',
    cssSupportedProps: {},
    currentTime: Math.floor((new Date()).getTime() / 1000),
    currentDay: new Date().getDay(),
    currentTimeInt: 0,
    nextNewClientRequest: 0,
    newClientInfoViewed: false,
    lastTimeActive: null,
    theme: '',
    isAppImage: false,
}

const getters = {
    [GET_SID]: state => state.socketId,
    [GET_IS_ELECTRON]: state => state.isElectron,
    [GET_CLIENT_VERSION]: state => state.clientVersionFull,
    [GET_NEW_CLIENT_INFO]: state => state.newClientInfo,
    [GET_CLIENT_CHANGES]: state => state.clientChanges,
    [SHOW_NEW_VERSION_NOTIFY]: state => {
        return !state.newClientInfoViewed && isBuildGreater(state.clientVersion, state.newClientInfo && state.newClientInfo.version)
    },
    [GET_CLIENT_UPDATE_STATUS]: state => state.updateStatus,
    [GET_CLIENT_DOWNLOAD_SIZE]: state => state.downloadInfo.totalSize,
    [GET_CLIENT_DOWNLOAD_CURRENT_SIZE]: state => state.downloadInfo.currentSize,
    [GET_LOCALE]: state => state.locale,
    [GET_CSS_PROPERTY_SUPPORTED]: state => propertyName => {
        if (!(propertyName in state.cssSupportedProps)) state.cssSupportedProps[propertyName] = (propertyName in document.body.style)
        return state.cssSupportedProps[propertyName]
    },
    [GET_CURRENT_TIME]: state => state.currentTime,
    [GET_CURRENT_DAY]: state => state.currentDay,
    [GET_LAST_TIME_ACTIVE]: state => state.lastTimeActive,
    [GET_MINUTES_RELATIVE_TO_TIME]: (state, getters) => (time = getters[GET_CURRENT_TIME]) => {
        let currentTime = getters[GET_CURRENT_TIME]
        let diff = moment.duration(moment.unix(currentTime).diff(moment.unix(time)))
        let minutes = Math.max(diff.asMinutes() || 0, 0)
        let seconds = Math.max(diff.seconds() || 0, 0)
        minutes = Math.floor(minutes).toString().padStart(2, '0')
        seconds = seconds.toString().padStart(2, '0')
        return `${minutes}:${seconds}`
    },
    getTheme: state => state.theme,
    getIsAppImage: state => state.isAppImage,
}
const actions = {
    // ------------------ Abstract actions --------------------------
    async [ACT_GET_NEW_CLIENT_INFO]({ state, dispatch, commit }, timeout) {
    },
    async [ACT_UPDATE_CLIENT]({state, commit}) {
    },
    [ACT_CHANGE_LOCALE]({ state, dispatch, commit }, locale) {
    },
    [ACT_UPDATE_NOTIFICATION]() {},
    // --------------------------------------------------------------

    setTimeUpdateInt({ state, dispatch, commit }) {
        if (!state.currentTimeInt)
            setInterval(() => { 
                const isSameDay = new Date().getDay() === state.currentDay
                if (!isSameDay) {
                    setTimeout(() => {
                        // console.log("🚀 ~ file: clientdata.js:147 ~ updating birthdays")
                        dispatch(`${CONTACTS}/${ACT_UPDATE_CONTACTS_BIRTHDAYS}`, null, { root: true })
                    }, 60*1000)
                }
                commit(MUT_SET_CURRENT_TIME, Math.floor((new Date()).getTime() / 1000))
                let timeDelta = state.currentTime - state.lastTimeActive
                if ( timeDelta > offlineMessageTime && !isOfflineMessageSent) {
                    dispatch(`${USERDATA}/${ACT_SET_CONTACT_STATUS}`, 'offline', { root: true })
                    isOfflineMessageSent = true
                }
            }, 1000);
    },
    setSocketId({ state, dispatch, commit }, socketId) {
        commit("setSocketId", socketId);
    },
    [ACT_SET_CLIENT_INFO]({ state, dispatch, commit }, info) {
        commit(MUT_SET_CLIENT_INFO, info);
    },
    [ACT_SET_LAST_TIME_ACTIVE]({commit, dispatch}, data) {
        commit(MUT_SET_LAST_TIME_ACTIVE, data)
        if (isOfflineMessageSent) {
            dispatch(`${USERDATA}/${ACT_SET_CONTACT_STATUS}`, 'online', { root: true })
            isOfflineMessageSent = false
        }
    },
    setTheme({ commit }, theme) {
        commit('mutSetTheme', theme)
    }
};
const mutations = {
    setSocketId (state, socketId) {
        console.log('setSocketId')
        console.log(socketId)
        state.socketId = socketId
    },
    [MUT_SET_NEW_CLIENT_INFO] (state, data) {
        state.newClientInfo = data
    },
    [MUT_SET_UPDATE_STATUS] (state, val) {
        state.updateStatus = val
    },
    [MUT_SET_CLIENT_DOWNLOAD_SIZE] (state, val) {
        state.downloadInfo.totalSize = val
    },
    [MUT_SET_CLIENT_DOWNLOAD_CURRENT_SIZE] (state, val) {
        state.downloadInfo.currentSize = val
    },
    [MUT_SET_LOCALE]: (state, val) => state.locale = val,
    [MUT_SET_CLIENT_INFO]: (state, val) => {
        if (val.userAgent) state.clientVersionFull = val.userAgent
        if (val.version) state.clientVersion = val.version
        if (val.arch) state.clientArch = val.arch
        if (val.os) state.os = val.os
    },
    [MUT_SET_CURRENT_TIME](state, time) {
        state.currentTime = time
        state.currentDay = new Date(state.currentTime * 1000).getDay()
    },
    [MUT_SET_NEXT_NEW_CLIENT_REQUEST](state, epoch) {
        state.nextNewClientRequest = epoch
    },
    [MUT_SET_NEW_CLIENT_INFO_VIEWED](state, val) {
        state.newClientInfoViewed = val
    },
    [MUT_SET_TIMER_CHECK_UPDATE](state, val) {
        state.timerCheckUpdate = val
    },
    [MUT_SET_CLIENT_CHANGES](state, val) {
        state.clientChanges = val
    },
    [MUT_SET_LAST_TIME_ACTIVE](state, { data }) {
        state.lastTimeActive = data
    },
    mutSetTheme(state, theme) {
        state.theme = theme
    },
    mutSetIsAppImage(state, val) {
        state.isAppImage = val
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
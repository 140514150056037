import IOClient from './IOClient'

import meetingsProto from './protocols/meetings'
import encryptionProto from './protocols/encryption'
import {encryptTextByPublicKey, generateHexString, importRsaKey} from '../../common/Encrypter'

export const CONNECTION_STATUS = {
    DISCONNECTED: 'disconnected',
    CONNECTING: 'connecting',
    RECONNECTING: 'reconnecting',
    CONNECTED: 'connected'
}

class MeetingIOClient extends IOClient {
    constructor (encryption) {
        super({logName: 'MeetingIOClient'})
        this.connectionStatus = CONNECTION_STATUS.DISCONNECTED
        this.encryption = encryption
        this.try_count = 0
        this.quiet = false
        this._subscribeOnSocket(encryption)
    }

    _subscribeOnSocket(encryption) {
        if (encryption) this.addSocketEmitters(encryptionProto.emitters)
        this.on('connect', this._onSocketConnected.bind(this))
        this.on('disconnect', this._onSocketDisconnected.bind(this))
        this.on('connect_error', this._onSocketDisconnected.bind(this))
    }

    async _onSocketConnected(socket) {
        [
            meetingsProto,
        ].forEach((proto) => {
            this.addSocketEmitters(proto.emitters)
            this.addSocketListeners(proto.listeners)
        })
        
        this.try_count = 0

        if (this.encryption) {
            let publicKey = await this.getPublicKey()
            publicKey = await importRsaKey(publicKey)
            let sessionKey = await generateHexString()
            await this.setSessionKey(await encryptTextByPublicKey(publicKey, sessionKey))
            this.setProtoSessionKey(sessionKey)
        }
        this.emit('connected')
    }

    _onSocketDisconnected() {
        this.try_count = 0

        let timeout = 5000
        if (this.try_count >= 6) timeout = 30000
        else if (this.try_count >= 3) timeout = 10000
        else if (this.try_count === 0) timeout = 0

        this.try_count++

        setTimeout(() => this.connect(this.server), timeout)
        this.emit('disconnected')
    }
}

export default MeetingIOClient

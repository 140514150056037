//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'

import {
    GET_USER_PARAMS,
    GET_MERGED_CONTACT_BY_ID,
    GET_RADIO_SETTINGS,
    RADIO_IS_TURN_ON,
    GET_IS_ELECTRON,
} from "../../../store/gettersTypes"
import {
    ACT_INFO_PUSH,
    CALLS_CONTROLLER,
    ACT_RADIO_STATE_TOGGLE,
    ACT_RC_RADIO_CONNECT,
    ACT_SELECT_CONTACT,
} from '../../../store/actionsTypes'

import {
    USERDATA,
    CONTACTS,
    INFO,
    PHONE_CNTL,
    RADIO,
    RC_RADIO,
    CLIENTDATA,
} from "../../../store/modulesNames"

import MainContentContainer from "./MainContentContainer.vue"
import ListItem from "../../custom/ListItem.vue"
import TechnicalJournals from "../../settings/TechnicalJournal.vue"
import Logger from '../../../common/Logger'

import {PHONE_CONTACT_FIELD_TYPE, SUPPORT_GROUP_CID} from '../../../constants'

import { getIsFullCompactMode, setSideVisible } from '../../../../ext/trackResizes'

export default {
    name: "MainContentSupport",
    components: { MainContentContainer, ListItem, TechnicalJournals },
    computed: {
        support() {
            return this[GET_USER_PARAMS] && this[GET_USER_PARAMS].support
        },
        supportCid() {
            const { cid = 0 } = this.support || {}
            return +cid
        },
        supportMail() {
            const { mail = '' } = this.support || {}
            return mail
        },
        isShowCheckGroup() {
            return Boolean(this.checkCallCid)
        },
        checkCallCid() {
            return this[GET_USER_PARAMS] && this[GET_USER_PARAMS].echoContact
        },
        checkCallPhoneNumber() {
            const supportContactData = this[GET_MERGED_CONTACT_BY_ID](this.checkCallCid) // this[GET_MERGED_CONTACT_BY_ID](this.supportCid)
            let phoneField = supportContactData.fields.find(f => PHONE_CONTACT_FIELD_TYPE.includes(f.type))
            return phoneField && phoneField.value
        },
        radioServer() {
            return this[GET_USER_PARAMS] && this[GET_USER_PARAMS].radioServer
        },
        ...mapGetters(CLIENTDATA, {isElectron: GET_IS_ELECTRON}),
        ...mapGetters(USERDATA, [GET_USER_PARAMS]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
        ...mapGetters(RADIO, {
            [GET_RADIO_SETTINGS]: GET_RADIO_SETTINGS,
            isTurnOn: RADIO_IS_TURN_ON,
        }),
    },
    methods: {
        doOpenChatSupport() {
            this.$emit('update:contact-selected', this.supportCid)
            this[ACT_SELECT_CONTACT](this.supportCid)
        },
        doSendMailSupport() {
            window.location.href = "mailto:" + this.supportMail +
                `?subject=${this.$t('support-mail-template-theme')}&body=${this.$t('support-mail-template-body')}`
        },
        getLogs(last) {
            Logger.getLogs(last)
        },
        makeCall({cid, number = false}) {
            let callData = {cid, number, video: false}
            this[CALLS_CONTROLLER]({type: 'call', data: callData})
        },
        radioCall() {
            const isFullCompactMode = getIsFullCompactMode()
            if (isFullCompactMode) setSideVisible()
            const radioWord = this.$t('radio')
            const radioToolbarBtn = document.querySelector(`[title=${radioWord}]`)
            radioToolbarBtn.click()
            try {
                const channelId = SUPPORT_GROUP_CID
                if (!this.isTurnOn) this.radioStateToggle({channelId, autoTurnOn: true})
                else this[ACT_RC_RADIO_CONNECT]({channelId})
            } catch(e) {
                console.log("!! MainContentSupport.vue -> radioConnect error: ", e)
            }
        },
        ...mapActions(CONTACTS, [ACT_SELECT_CONTACT]),
        ...mapActions(INFO, [ACT_INFO_PUSH]),
        ...mapActions(PHONE_CNTL, [CALLS_CONTROLLER]),
        ...mapActions(RADIO, {
            radioStateToggle: ACT_RADIO_STATE_TOGGLE,
        }),
        ...mapActions(RC_RADIO, [ACT_RC_RADIO_CONNECT]),
    }
}
